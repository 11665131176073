export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    refName: {
      type: String,
      required: true,
    },
    max: {
      type: String,
      default: undefined,
    },
    min: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayPicker: false,
      innerValue: '',
    };
  },

  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

};
