export default {
  athletes(currentState, value) {
    const state = currentState;
    state.athletes = Object.assign([], value);
  },
  athlete(currentState, value) {
    const state = currentState;
    state.athlete = { ...value };
  },
};
