export default {
  group(currentState, value) {
    const state = currentState;
    state.group = { ...value };
  },

  groups(currentState, value) {
    const state = currentState;
    state.groups = Object.assign([], value);
  },

  groupMembers(currentState, value) {
    const state = currentState;
    state.groupMembers = Object.assign([], value);
  },

  athleteGroups(currentState, value) {
    const state = currentState;
    state.athleteGroups = Object.assign([], value);
  },

  coachGroupMembers(currentState, value) {
    const state = currentState;
    state.coachGroupMembers = Object.assign([], value);
  },
};
