import moment from 'moment';
import { GOALS } from '../config/constants';

export function readFileBase64(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(window.btoa(temporaryFileReader.result));
    };
    temporaryFileReader.readAsBinaryString(inputFile);
  });
}

export function readFile(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsBinaryString(inputFile);
  });
}

export function base64toBlob(base64Data, contentTypeValue) {
  const contentType = contentTypeValue || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  // eslint-disable-next-line
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    // eslint-disable-next-line
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function getCurrentTimestamp() {
  return moment().format('DD-MM-YYYY HH:mm:ss');
}

export function calculateWeeklyGoal(payload) {
  const benchmark = payload;
  let calculatedGoal = '';
  let goalAmount = 0;

  if (benchmark.recent.length && benchmark.weeklyGoalBase.length > 0) {
    switch (benchmark.goalType) {
      case GOALS.TYPES.WEIGHT:
      case GOALS.TYPES.REPS:
      case GOALS.TYPES.DISTANCE: {
        // Calculate new goal amount
        goalAmount = parseFloat(benchmark.weeklyGoalBase) * (benchmark.weeklyGoalPercentage / 100);

        // Check goal direction and base don that either increase or decrease
        if (benchmark.goalDirection === GOALS.DIRECTIONS.UP) {
          calculatedGoal = parseFloat(benchmark.weeklyGoalBase) + goalAmount;
        } else if (benchmark.goalDirection === GOALS.DIRECTIONS.DOWN) {
          calculatedGoal = parseFloat(benchmark.weeklyGoalBase) - goalAmount;
        }

        break;
      }
      case GOALS.TYPES.TIME: {
        // Convert the time to seconds so it can be used in calculations
        const goalInSeconds = moment.duration(benchmark.weeklyGoalBase).asSeconds();
        goalAmount = goalInSeconds * (benchmark.weeklyGoalPercentage / 100);

        // Check goal direction and base don that either increase or decrease
        if (benchmark.goalDirection === GOALS.DIRECTIONS.UP) {
          calculatedGoal = new Date(1000 * (goalInSeconds + goalAmount)).toISOString().substr(11, 8);
        } else if (benchmark.goalDirection === GOALS.DIRECTIONS.DOWN) {
          calculatedGoal = new Date(1000 * (goalInSeconds - goalAmount)).toISOString().substr(11, 8);
        }

        break;
      }
      default:
    }
  }

  // Always return a string value
  return `${calculatedGoal}`;
}
