import notify from '@/utils/notify';
import { db, app } from '@/utils/firebase';
import router from '@/router';
import {
  insertStoreArrayItem,
  updateStoreArrayItem,
  deleteStoreArrayItem,
} from '@/utils/storeUtils';

import ErrorEvent from '../../../models/ErrorEvent';
import User from '../../../models/User';

const usersRef = db.collection('users');
const coachAthletesRef = db.collection('coachAthletes');
const groupMembersRef = db.collection('groupMembers');

export default {
  async getCoachAthletes({ commit, rootGetters }, coachUserId) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;

      const querySnaphot = await coachAthletesRef
        .where('coachId', '==', coachUserId)
        .where('profileId', '==', profileId)
        .get();

      if (querySnaphot.empty) {
        commit('athletes', []);
        return Promise.resolve([]);
      }

      const getAthletePromises = [];
      querySnaphot.docs.forEach((athleteDoc) => {
        getAthletePromises.push(usersRef.doc(athleteDoc.data().athleteId).get());
      });

      const athleteDocs = await Promise.all(getAthletePromises);
      const coachAthletes = athleteDocs.map((x) => new User(x.data()));

      commit('athletes', coachAthletes);

      return Promise.resolve(coachAthletes);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Coach Athletes');

      return Promise.resolve();
    }
  },

  async createNewAthlete({ commit }) {
    commit('athlete', new User());

    router.push({
      name: 'CoachAthlete',
      params: {
        mode: 'create',
      },
    });
  },

  async createAthlete({ dispatch, getters, rootGetters }, payload) {
    try {
      if (!payload.athlete) {
        notify.error('An unexepcted error accured, please try again later');
        return Promise.reject();
      }

      // Validate if the user already exists
      const { athletes } = getters;
      if (athletes.find((x) => x.id === payload.athlete.email)) {
        notify.error('Athlete with the same email address already exist');
        return Promise.reject();
      }

      const profileId = rootGetters['profiles/activeProfile'].id;
      const newAthlete = new User(payload.athlete);
      newAthlete.isInjured = false;
      newAthlete.rating = 0;
      newAthlete.profileId = profileId;

      const athletePayload = {
        athleteId: newAthlete.email,
        coachId: payload.coachId,
        profileId,
      };

      await coachAthletesRef
        .doc(`${payload.coachId}_${newAthlete.email}`)
        .set(athletePayload);

      await usersRef.doc(newAthlete.email).set(newAthlete.serialize());

      await dispatch('benchmarks/createAthleteBenchmarks', athletePayload, {
        root: true,
      });

      if (payload.groupIds && payload.groupIds.length > 0) {
        const batch = db.batch();

        payload.groupIds.forEach((groupId) => {
          const groupMemberId = `${groupId}_${newAthlete.id}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);

          batch.set(groupMemberDocRef, {
            groupId,
            athleteId: newAthlete.id,
            coachId: payload.coachId,
            profileId,
          });
        });

        await batch.commit();
      }

      insertStoreArrayItem('athletes/athletes', newAthlete);
      notify.success('Athlete created successfully');

      if (payload.sendSigninLink) {
        const activationPayload = {
          userId: newAthlete.email,
          fullname: newAthlete.fullname,
        };

        await dispatch('session/sendActivationCode', activationPayload, { root: true });

        notify.info('Login link sent to athlete');
      } else {
        notify.warning('Login link not sent to athlete');
      }

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Athlete');

      return Promise.resolve();
    }
  },

  async updateAthlete({ commit, rootGetters }, payload) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;
      const athlete = new User(payload.athlete);

      await usersRef.doc(athlete.email).set(athlete.serialize());

      updateStoreArrayItem('athletes/athletes', athlete);
      commit('athlete', athlete);

      if (payload.groupIds) {
        const batch = db.batch();

        const deselectedGroups = rootGetters['groups/athleteGroups'].filter(
          x => !payload.groupIds.includes(x),
        );

        // Batch Remove groups deselected from the list
        deselectedGroups.forEach((groupId) => {
          const groupMemberId = `${groupId}_${athlete.id}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);
          batch.delete(groupMemberDocRef);
        });

        // Batch set new selected groups
        payload.groupIds.forEach((groupId) => {
          const groupMemberId = `${groupId}_${athlete.id}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);
          batch.set(groupMemberDocRef, {
            groupId,
            athleteId: athlete.id,
            coachId: payload.coachId,
            profileId,
          });
        });

        await batch.commit();
      }

      notify.success('Athlete updated successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Athlete');

      return Promise.resolve();
    }
  },

  // THIS IS A TEMP FUNCTION AND NEEDS TO BE REMOVED
  async createCoachAthleteAccounts({ dispatch }, coachId) {
    try {
      const athletes = await dispatch('getCoachAthletes', coachId);

      await dispatch('createOutstandingCoachAthleteAccounts', athletes);

      notify.success('Successfully created and updated athlete accounts');
      return Promise.resolve();
    } catch (error) {
      notify.error(error);
      return Promise.resolve();
    }
  },

  async sendPasswordResetEmailToAllAthletes({ dispatch, getters }) {
    try {
      const sendPasswordPromises = [];

      // get all athlete ids from state
      const { athletes } = getters;

      // Check for outstanding account creations on auth
      await dispatch('createOutstandingCoachAthleteAccounts', athletes);

      for (let i = 0; i < athletes.length; i += 1) {
        // send password reset email for user
        sendPasswordPromises.push(app.auth().sendPasswordResetEmail(athletes[i].id));
      }

      await Promise.all(sendPasswordPromises);

      notify.success('Password reset emails sent successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Send reset password emails to all users');

      return Promise.resolve();
    }
  },

  async createOutstandingCoachAthleteAccounts(context, athletes) {
    try {
      const createAuthPromises = [];

      athletes.forEach((athlete) => {
        if (!athlete.userId) {
          createAuthPromises.push(app.auth().createUserWithEmailAndPassword(athlete.email, 'manage').then());
        }
      });

      await Promise.all(createAuthPromises);

      notify.success('Successfully created and updated athlete accounts');
      return Promise.resolve();
    } catch (error) {
      notify.error(error);
      return Promise.resolve();
    }
  },

  // Work in progress
  // async sendResetEmailToAthlete(payload) {
  //   try {
  //     const auth = firebase.app.auth();
  //
  //     const athlete = new User(payload.athlete);
  //
  //     auth.sendPasswordResetEmail(athlete.email)
  //       .then(() => {
  //         notify.success('Email sent');
  //       })
  //       .catch(() => {
  //         notify.success('Send reset email failed');
  //       });
  //     return Promise.resolve();
  //   } catch (error) {
  //     notify.error(error.message);
  //
  //     const errorHandler = new ErrorEvent();
  //     errorHandler.sendError(error, 'Send reset password emails to all users');
  //
  //     return Promise.resolve();
  //   }
  // },

  async updateAthleteFields({ rootGetters, commit }, payload) {
    try {
      await usersRef.doc(payload.athleteId).update(payload.athlete);

      const updatedUser = { ...rootGetters['session/user'], ...payload.athlete };
      commit('session/user', updatedUser, { root: true });

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Athlete Fields');

      return Promise.resolve();
    }
  },

  async deleteAthlete({ dispatch }, payload) {
    try {
      if (!payload.athleteId) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      // This should force the user to be logged out
      dispatch('session/killUserSession', payload.athleteId, { root: true });

      await usersRef.doc(payload.athleteId).delete();

      deleteStoreArrayItem('athletes/athletes', { id: payload.athleteId });

      notify.success('Athlete deleted successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Athlete');

      return Promise.resolve();
    }
  },

  async selectAthlete({ commit }, athlete) {
    commit('athlete', athlete);

    router.push({
      name: 'CoachAthlete',
      params: {
        mode: 'update',
      },
    });
  },

  async selectBenchmarkScoreAthlete({ commit }, athlete) {
    commit('athlete', athlete);

    router.push({
      name: 'CoachScore',
    });
  },

};
