import moment from 'moment';

import BaseModel from './BaseModel';

export default class Activation extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.token = object.token || null;
    this.userId = object.userId || null;
    this.expiry = object.expiry ||
      moment(new Date())
        .add(1, 'days')
        .format('YYYYMMDDHHmmssSSS');
  }
}
