import { EventBus } from '@/utils/event-bus';

function error(text, persist) {
  const persistSnackbar = persist || false;
  EventBus.$emit('notify', 'error', text, 'mdi-alert-circle-outline', persistSnackbar, 5000);
}

function info(text, persist) {
  const persistSnackbar = persist || false;
  EventBus.$emit('notify', 'info', text, 'mdi-information-variant', persistSnackbar);
}

function success(text, persist) {
  const persistSnackbar = persist || false;
  EventBus.$emit('notify', 'success', text, 'mdi-check', persistSnackbar);
}

function warning(text, persist) {
  const persistSnackbar = persist || false;
  EventBus.$emit('notify', 'warning', text, 'mdi-alert', persistSnackbar);
}

export default {
  error,
  info,
  success,
  warning,
};
