import _isNil from 'lodash/isNil';

import notify from '@/utils/notify';
import { db, firestore } from '@/utils/firebase';
import {
  insertStoreArrayItem,
  updateStoreArrayItem,
  deleteStoreArrayItem,
} from '@/utils/storeUtils';

import Profile from '../../../models/Profile';
import ErrorEvent from '../../../models/ErrorEvent';

export default {
  async loadCoachProfiles({ commit, dispatch }, coachUserId) {
    try {
      const coachProfiles = await dispatch('getProfiles', coachUserId);
      commit('profiles', coachProfiles);

      return coachProfiles;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Load Coach Profiles');

      return Promise.resolve();
    }
  },

  async getProfiles({ commit }, userId) {
    try {
      commit('profiles', []);

      const profilesRef = db.collection(`users/${userId}/profiles`);
      const profilesSnap = await profilesRef.get();
      const profiles = profilesSnap.docs.map((doc) => new Profile(doc.data()));

      return profiles;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Profiles');

      return Promise.resolve();
    }
  },

  async selectActiveProfile({ commit }, payload) {
    try {
      const usersRef = db.collection('users');
      await usersRef.doc(payload.coachId).update({
        activeProfile: payload.profile.serialize(),
      });

      commit('activeProfile', payload.profile);

      return payload.profile;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Select Active Profile');

      return Promise.resolve();
    }
  },

  async setActiveProfile({ commit }, payload) {
    try {
      if (_isNil(payload.profileId) || payload.profileId.trim().length === 0) {
        notify.warning('No selected Profile');
        commit('activeProfile', new Profile());
        return Promise.resolve();
      }

      const profilesRef = db.collection(`users/${payload.coachId}/profiles`);
      const profileDoc = await profilesRef.doc(payload.profileId).get();

      commit('activeProfile', new Profile(profileDoc.data()));

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Set Active Profile');

      return Promise.resolve();
    }
  },

  async  createProfile({ getters, dispatch }, payload) {
    try {
      if (!payload.profile) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      // Validate if the new name already existingUser
      if (
        getters.profiles.find(
          x => x.name.toLowerCase() === payload.profile.name.toLowerCase() &&
              x.sport === payload.profile.sport,
        )
      ) {
        notify.warning(
          'A profile with this name already exist, please use a unique name',
        );
        return Promise.resolve();
      }

      const newProfile = await dispatch('createUserProfile', payload);

      insertStoreArrayItem('profiles/profiles', newProfile);

      await dispatch('selectActiveProfile', {
        coachId: payload.coachId,
        profile: newProfile,
      });

      notify.success('Profile created successfully');

      return newProfile;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Profile');

      return Promise.resolve();
    }
  },

  async createUserProfile(context, payload) {
    try {
      if (!payload.profile) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      const profilesRef = db.collection(`users/${payload.coachId}/profiles`);
      const profilesDocRef = profilesRef.doc();
      const newProfile = new Profile(payload.profile);
      newProfile.id = profilesDocRef.id;

      await profilesDocRef.set(newProfile.serialize());

      return newProfile;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create User Profile');

      return Promise.resolve();
    }
  },

  async updateProfile({ commit, getters }, payload) {
    try {
      if (!payload.profile) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      // Validate if the new name already existingUser
      if (
        getters.profiles.find(
          x => x.name.toLowerCase() === payload.profile.name.toLowerCase() &&
              x.sport === payload.profile.sport,
        )
      ) {
        notify.warning(
          'A profile with this name already exist, please use a unique name',
        );
        return Promise.resolve();
      }

      const profilesRef = db.collection(`users/${payload.coachId}/profiles`);
      const updateProfile = new Profile(payload.profile);

      await profilesRef.doc(updateProfile.id).set(updateProfile.serialize());

      updateStoreArrayItem('profiles/profiles', updateProfile);

      if (getters.activeProfile.id === updateProfile.id) {
        commit('activeProfile', updateProfile);
      }

      notify.success('Profile created successfully');

      return updateProfile;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Profile');

      return Promise.resolve();
    }
  },

  async deleteProfile({ getters, commit }, payload) {
    try {
      if (!payload.profileId) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      const profilesRef = db.collection(`users/${payload.coachId}/profiles`);
      await profilesRef.doc(payload.profileId).delete();

      // If the active profile is deleted, then update the user record
      if (getters.activeProfile.id === payload.profileId) {
        const usersDocRef = db.collection('users').doc(payload.coachId);

        await usersDocRef.update({
          activeProfile: firestore.FieldValue.delete(),
        });
        commit('activeProfile', Profile());
      }

      deleteStoreArrayItem('profiles/profiles', { id: payload.profileId });

      notify.success('Profile deleted successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Profile');

      return Promise.resolve();
    }
  },

};
