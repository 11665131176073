import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import store from '@/store';

export function findStoreArrayItem(name, fieldName, fieldValue) {
  const items = store.getters[name];
  const index = _findIndex(items, x => x[fieldName] === fieldValue);

  return items[index];
}

export function updateStoreArrayItem(name, value) {
  const items = _cloneDeep(store.getters[name]);
  const index = _findIndex(items, x => x.id === value.id);
  items.splice(index, 1, value);

  store.commit(name, items);
}

export function insertStoreArrayItem(name, value) {
  const items = _cloneDeep(store.getters[name]);
  items.push(value);
  store.commit(name, items);
}

export function deleteStoreArrayItem(name, value) {
  const items = store.getters[name];
  const index = _findIndex(items, x => x.id === value.id);
  items.splice(index, 1);
  store.commit(name, items);
}
