import Vue from 'vue';
import Vuex from 'vuex';
import CreatePersistedState from 'vuex-persistedstate';
import createLogger from 'vuex/dist/logger';
import { generateVuexStoreModuleConfiguration } from 'vuex-module-configuration-composer';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const context = require.context('./modules', true, /index\.js$/);

const presistedStateInstance = CreatePersistedState({
  paths: ['session', 'athletes', 'groups', 'profiles', 'ads', 'benchmarks', 'logs'],
});

const storeConfiguration = {
  strict: debug,
  plugins: debug ? [createLogger(), presistedStateInstance] : [presistedStateInstance],
  ...generateVuexStoreModuleConfiguration(context),
};

export default new Vuex.Store(storeConfiguration);
