import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,

    options: {
      customProperties: true,
    },

    themes: {
      light: {
        // primary: '#1E9C5A',
        primary: '#BED63B',
        secondary: colors.lightBlue.darken3,
        accent: colors.blueGrey.darken2,
        error: '#E1112C',
        warning: colors.deepOrange.base,
        info: colors.blue.base,
        success: '#4caf50',
      },

      dark: {
        primary: '#BED63B',
        secondary: colors.lightBlue.darken3,
        accent: colors.blueGrey.darken2,
        error: '#E1112C',
        warning: colors.deepOrange.base,
        info: colors.blue.base,
        success: '#4caf50',
      },
    },
  },
});
