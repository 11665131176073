import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import _padEnd from 'lodash/padEnd';
import _chunk from 'lodash/chunk';
import { boolean } from 'boolean';

import { calculateWeeklyGoal } from '@/utils';
import { db } from '@/utils/firebase';
import notify from '@/utils/notify';
import router from '@/router';

import { DEFAULT_BENCHMARKS, DEFAULT_GOAL } from '@/config/defaults';
import { GOALS } from '@/config/constants';
import {
  insertStoreArrayItem,
  updateStoreArrayItem,
  deleteStoreArrayItem,
} from '@/utils/storeUtils';

import Benchmark from '../../../models/Benchmark';
import AthleteBenchmarkHistory from '../../../models/AthleteBenchmarkHistory';
import AthleteBenchmark from '../../../models/AthleteBenchmark';
import ErrorEvent from '../../../models/ErrorEvent';
import User from '../../../models/User';

const benchmarksRef = db.collection('benchmarks');
const coachAthletesRef = db.collection('coachAthletes');
const usersRef = db.collection('users');
const athleteBenchmarksRef = db.collection('athleteBenchmarks');

export default {
  async getCoachBenchmarks({ commit, rootGetters }, coachUserId) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;

      const benchmarksSnap = await benchmarksRef
        .where('coachId', '==', coachUserId)
        .where('profileId', '==', profileId)
        .get();

      if (benchmarksSnap.empty) {
        commit('benchmarks', []);
        return Promise.resolve([]);
      }

      const benchmarks = benchmarksSnap.docs.map((x) => new Benchmark(x.data()));

      commit('benchmarks', benchmarks);

      return Promise.resolve(benchmarks);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Coach Benchmarks');

      return Promise.resolve();
    }
  },

  async getAthleteBenchmarks({ commit, dispatch, rootGetters }, payload) {
    try {
      const athleteBenchmarks = {};
      const athleteDataPromise = [];
      const profileId = rootGetters['profiles/activeProfile'].id;

      commit('athleteBenchmarks', []);

      let athleteBenchmarksWhereRef = athleteBenchmarksRef.where(
        'profileId',
        '==',
        profileId,
      );

      if (payload.athleteId) {
        athleteBenchmarksWhereRef = athleteBenchmarksWhereRef.where(
          'athleteId',
          '==',
          payload.athleteId,
        );
      }

      if (payload.benchmarkId) {
        athleteBenchmarksWhereRef = athleteBenchmarksWhereRef.where(
          'benchmarkId',
          '==',
          payload.benchmarkId,
        );
      }

      if (payload.type) {
        athleteBenchmarksWhereRef = athleteBenchmarksWhereRef.where(
          'type',
          '==',
          payload.type,
        );
      }

      const athleteBenchmarksSnap = await athleteBenchmarksWhereRef.get();

      if (athleteBenchmarksSnap.empty) {
        commit('athleteBenchmarks', []);
        return Promise.resolve([]);
      }

      // eslint-disable-next-line
      for (const athleteBenchmarkDoc of athleteBenchmarksSnap.docs) {
        const athleteBenchmarkData = new AthleteBenchmark(athleteBenchmarkDoc.data());

        if (!payload.athleteId && payload.benchmarkId) {
          athleteBenchmarks[athleteBenchmarkData.athleteId] = athleteBenchmarkData;
        } else {
          athleteBenchmarks[athleteBenchmarkData.benchmarkId] = athleteBenchmarkData;
        }

        athleteDataPromise.push(usersRef.doc(athleteBenchmarkData.athleteId).get());
      }

      if (!payload.athleteId && payload.benchmarkId) {
        const athleteDocs = await Promise.all(athleteDataPromise);

        const groupMembers = await dispatch(
          'groups/getCoachGroupMembers',
          payload.coachId,
          {
            root: true,
          },
        );

        for (let i = 0; i < athleteDocs.length; i += 1) {
          // ******************
          // Populate with Athlete Data
          const athleteDataElement = new User(athleteDocs[i].data());
          athleteBenchmarks[athleteDataElement.id].athlete = {
            fullname: athleteDataElement.fullname,
            weight: athleteDataElement.bodyComposition.weight,
          };

          // ******************
          // Populate with Groups
          athleteBenchmarks[athleteDataElement.id].groups = groupMembers
            .filter(x => x.athleteId === athleteDataElement.id)
            .map(x => x.groupId);

          // ******************
          // Populate with goal mask properties
          let defaultGoal;
          if (
            athleteBenchmarks[athleteDataElement.id].goalType === GOALS.TYPES.WEIGHT &&
              athleteBenchmarks[athleteDataElement.id].isMultiplied
          ) {
            defaultGoal = _cloneDeep(
              DEFAULT_GOAL[
                `${athleteBenchmarks[athleteDataElement.id].goalType}_MULTIPLIED`
              ],
            );
          } else {
            defaultGoal = _cloneDeep(
              DEFAULT_GOAL[`${athleteBenchmarks[athleteDataElement.id].goalType}`],
            );
          }

          athleteBenchmarks[athleteDataElement.id].mask = defaultGoal.mask;
          athleteBenchmarks[athleteDataElement.id].description = defaultGoal.description;
          athleteBenchmarks[athleteDataElement.id].suffix = defaultGoal.suffix;
          athleteBenchmarks[athleteDataElement.id].fieldType = defaultGoal.fieldType;
        }

        const returnAthleteBenchmarkElements = Object.values(athleteBenchmarks);
        commit('athleteBenchmarks', returnAthleteBenchmarkElements);

        return Promise.resolve(returnAthleteBenchmarkElements);
      }

      const athleteDoc = await usersRef.doc(payload.athleteId).get();
      const athleteData = new User(athleteDoc.data());

      Object.keys(athleteBenchmarks).forEach((key) => {
        // ******************
        // Populate with Athlete Data
        athleteBenchmarks[key].athlete = {
          fullname: athleteData.fullname,
          position: athleteData.position,
          weight: athleteData.bodyComposition.weight,
        };

        // ******************
        // Populate with goal mask properties
        const defaultGoal = _cloneDeep(
          DEFAULT_GOAL[`${athleteBenchmarks[key].goalType}`],
        );

        athleteBenchmarks[key].mask = defaultGoal.mask;
        athleteBenchmarks[key].description = defaultGoal.description;
        athleteBenchmarks[key].suffix = defaultGoal.suffix;
        athleteBenchmarks[key].fieldType = defaultGoal.fieldType;
      });

      const returnAthleteBenchmarks = Object.values(athleteBenchmarks);
      commit('athleteBenchmarks', returnAthleteBenchmarks);

      return Promise.resolve(returnAthleteBenchmarks);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Athlete Benchmarks');

      return Promise.resolve();
    }
  },

  async createNewBenchmark({ commit }) {
    commit('benchmark', new Benchmark());

    router.push({
      name: 'CoachBenchmark',
      params: {
        mode: 'create',
      },
    });
  },

  async createBenchmark({ getters, rootGetters }, payload) {
    try {
      if (!payload.benchmark) {
        notify.error('An unexpected error occured, please try again');
        return Promise.resolve();
      }

      // Validate if the new benchmarkName already exists
      if (getters.benchmarks.find(x => x.benchmarkName.toLowerCase() ===
            payload.benchmark.benchmarkName.toLowerCase())) {
        notify.warning('A benchmark with this name already exist, please use a unique name');
        return Promise.resolve();
      }

      const profileId = rootGetters['profiles/activeProfile'].id;
      const benchmarksDocRef = benchmarksRef.doc();
      const newBenchmark = new Benchmark(payload.benchmark);
      newBenchmark.id = benchmarksDocRef.id;
      newBenchmark.profileId = profileId;

      // Set the new Benchmark object
      await benchmarksDocRef.set(newBenchmark.serialize());
      insertStoreArrayItem('benchmarks/benchmarks', newBenchmark);

      // Setup all existing athletes with the new benchmarkId
      const coachAthletesSnap = await coachAthletesRef
        .where('coachId', '==', payload.benchmark.coachId)
        .where('profileId', '==', profileId)
        .get();

      const batch = db.batch();

      // eslint-disable-next-line
      for (const coachAthleteDoc of coachAthletesSnap.docs) {
        const coachAthleteData = coachAthleteDoc.data();

        const athleteBenchmark = new AthleteBenchmark({
          athleteId: coachAthleteData.athleteId,
          benchmarkId: newBenchmark.id,
          benchmarkName: newBenchmark.benchmarkName,
          type: newBenchmark.type,
          profileId,
        });

        const athleteBenchmarkId = `${newBenchmark.id}_${athleteBenchmark.athleteId}`;
        const athleteBenchmarkDocRef = athleteBenchmarksRef.doc(athleteBenchmarkId);
        batch.set(athleteBenchmarkDocRef, athleteBenchmark.serialize());
      }

      await batch.commit();

      notify.success('Benchmark created successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Benchmark');

      return Promise.resolve();
    }
  },

  async createAthleteBenchmarks({ dispatch, rootGetters }, payload) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;
      const benchmarks = await dispatch('getCoachBenchmarks', payload.coachId);

      const batch = db.batch();

      benchmarks.forEach((benchmark) => {
        const athleteBenchmark = new AthleteBenchmark();
        athleteBenchmark.id = `${benchmark.id}_${payload.athleteId}`;
        athleteBenchmark.athleteId = payload.athleteId;
        athleteBenchmark.benchmarkId = benchmark.id;
        athleteBenchmark.benchmarkName = benchmark.benchmarkName;
        athleteBenchmark.type = benchmark.type;
        athleteBenchmark.profileId = profileId;
        athleteBenchmark.coachId = payload.coachId;

        const athleteBenchmarkDocRef = athleteBenchmarksRef.doc(athleteBenchmark.id);
        batch.set(athleteBenchmarkDocRef, athleteBenchmark.serialize());
      });

      await batch.commit();

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Athlete Benchmarks');

      return Promise.resolve();
    }
  },

  async updateBenchmark(context, payload) {
    try {
      const benchmark = new Benchmark(payload.benchmark);

      const batch = db.batch();
      const benchmarkDocRef = benchmarksRef.doc(benchmark.id);
      batch.set(benchmarkDocRef, benchmark.serialize());

      const athleteBenchmarksSnap = await athleteBenchmarksRef
        .where('benchmarkId', '==', benchmark.id)
        .get();

      athleteBenchmarksSnap.forEach((athleteBenchmarkDoc) => {
        batch.update(athleteBenchmarkDoc.ref, {
          benchmarkName: benchmark.benchmarkName,
          type: benchmark.type,
        });
      });

      await batch.commit();

      updateStoreArrayItem('benchmarks/benchmarks', benchmark);

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Benchmark');

      return Promise.resolve();
    }
  },

  async updateAthleteBenchmark(context, payload) {
    try {
      const { benchmark } = payload;
      const benchmarkId = `${benchmark.benchmarkId}_${benchmark.athleteId}`;

      if (benchmark.goalType === GOALS.TYPES.TIME) {
        benchmark.recent = _padEnd(benchmark.recent, 8, '00:');
      }

      const recentValue = parseInt(benchmark.recent.replace(new RegExp(':', 'g'), ''), 10);
      let bestValue = parseInt(benchmark.best.replace(new RegExp(':', 'g'), ''), 10);

      if (benchmark.best === '') {
        bestValue = benchmark.goalDirection === GOALS.DIRECTIONS.UP ? 0 : 999999;
      }

      if (benchmark.goalDirection === GOALS.DIRECTIONS.UP) {
        benchmark.best = recentValue > bestValue ? benchmark.recent : benchmark.best;
      } else if (benchmark.goalDirection === GOALS.DIRECTIONS.DOWN) {
        benchmark.best = recentValue < bestValue ? benchmark.recent : benchmark.best;
      }

      await athleteBenchmarksRef
        .doc(benchmarkId)
        .update({ recent: benchmark.recent, best: benchmark.best });

      const newAthleteBenchmarkHisotryItem = new AthleteBenchmarkHistory(benchmark);

      const scoreDate = moment(benchmark.scoreDate).format('YYYYMMDD');

      await athleteBenchmarksRef
        .doc(benchmarkId)
        .collection('history')
        .doc(scoreDate)
        .set(newAthleteBenchmarkHisotryItem.serialize());

      updateStoreArrayItem('benchmarks/athleteBenchmarks', benchmark);

      notify.success('Benchmark Updated');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Athlete Benchmark');

      return Promise.resolve();
    }
  },

  async updateAthleteBenchmarkGoalsBulk({ commit }, payload) {
    try {
      const { athleteBenchmarks } = payload;

      if (athleteBenchmarks.length > 0) {
        const batch = db.batch();

        for (let i = 0; i < athleteBenchmarks.length; i += 1) {
          const athleteBenchmarkId = `${athleteBenchmarks[i].benchmarkId}_${
            athleteBenchmarks[i].athleteId
          }`;

          const athleteBenchmarksDocRef = athleteBenchmarksRef.doc(athleteBenchmarkId);

          const updateDocument = {
            goal: athleteBenchmarks[i].goal,
            goalType: athleteBenchmarks[i].goalType,
            goalDirection: athleteBenchmarks[i].goalDirection,
            isMultiplied: athleteBenchmarks[i].isMultiplied,
            weeklyGoalPercentage: athleteBenchmarks[i].weeklyGoalPercentage,
            weeklyGoalBase: athleteBenchmarks[i].weeklyGoalBase,
          };

          if (boolean(athleteBenchmarks[i].selected)) {
            updateDocument.weeklyGoal = calculateWeeklyGoal(athleteBenchmarks[i]);
          }

          batch.update(athleteBenchmarksDocRef, updateDocument);
        }

        await batch.commit();
      }

      commit('athleteBenchmarks', athleteBenchmarks);
      notify.success('Benchmark Goals Updated');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Athlete Benchmark Goals Bulk');

      return Promise.resolve();
    }
  },

  async deleteBenchmark(context, payload) {
    try {
      if (!payload.benchmarkId) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      const batch = db.batch();
      const benchmarkDeleteRef = benchmarksRef.doc(payload.benchmarkId);

      batch.delete(benchmarkDeleteRef);

      const athleteBenchmarkSnap = await athleteBenchmarksRef
        .where('benchmarkId', '==', payload.benchmarkId)
        .get();

      // eslint-disable-next-line
      for (const athleteBenchmarkDoc of athleteBenchmarkSnap.docs) {
        const athleteBenchmarkId = `${payload.benchmarkId}_${
          athleteBenchmarkDoc.data().athleteId
        }`;
        batch.delete(athleteBenchmarksRef.doc(athleteBenchmarkId));
      }

      await batch.commit();

      deleteStoreArrayItem('benchmarks/benchmarks', { id: payload.benchmarkId });

      notify.success('Benchmark deleted successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Benchmark');

      return Promise.resolve();
    }
  },

  async deleteAthleteBenchmarks(context, athleteId) {
    try {
      const batch = db.batch();

      const athleteBenchmarkSnap = await athleteBenchmarksRef
        .where('athleteId', '==', athleteId)
        .get();

      athleteBenchmarkSnap.docs.forEach((athleteBenchmarkDoc) => {
        const athleteBenchmarkId = `${
          athleteBenchmarkDoc.data().benchmarkId
        }_${athleteId}`;
        batch.delete(athleteBenchmarksRef.doc(athleteBenchmarkId));
      });

      await batch.commit();

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Athlete Benchmarks');

      return Promise.resolve();
    }
  },

  async createDefaultBenchmarks({ getters, dispatch, rootGetters }, payload) {
    try {
      const existingBenchmarks = getters.benchmarks;
      const profileId = rootGetters['profiles/activeProfile'].id;

      const missingBenchmarks = DEFAULT_BENCHMARKS.filter(
        x => !existingBenchmarks.find(y => y.benchmarkName === x.benchmarkName),
      );

      missingBenchmarks.forEach((missingBenchmark) => {
        const benchmarkData = {
          benchmark: new Benchmark(missingBenchmark),
        };

        benchmarkData.benchmark.coachId = payload.coachId;
        benchmarkData.benchmark.profileId = profileId;

        dispatch('createBenchmark', benchmarkData);
      });

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Default Benchmarks');

      return Promise.resolve();
    }
  },

  async selectBenchmark({ commit }, benchmark) {
    commit('benchmark', benchmark);

    router.push({
      name: 'CoachBenchmark',
      params: {
        mode: 'update',
      },
    });
  },

  async progressWeek() {
    const batchUpdateRecords = [];

    // Get all athleteBenchmarks
    const athleteBenchmarksSnap = await athleteBenchmarksRef.get();

    const athleteBenchmarks = athleteBenchmarksSnap.docs.map((x) => new AthleteBenchmark(x.data()));

    // eslint-disable-next-line
    for (const athleteBenchmark of athleteBenchmarks) {
      let calculatedGoal = '';
      let goalAmount = 0;

      if (athleteBenchmark.recent &&
        athleteBenchmark.recent.length > 0 &&
        athleteBenchmark.weeklyGoalBase &&
        athleteBenchmark.weeklyGoalBase.length > 0) {
        switch (athleteBenchmark.goalType) {
          case GOALS.TYPES.WEIGHT:
          case GOALS.TYPES.REPS:
          case GOALS.TYPES.DISTANCE: {
            // Calculate new goal amount
            goalAmount = parseFloat(athleteBenchmark.weeklyGoalBase) * (athleteBenchmark.weeklyGoalPercentage / 100);

            // Check goal direction and base don that either increase or decrease
            if (athleteBenchmark.goalDirection === GOALS.DIRECTIONS.UP) {
              calculatedGoal = parseFloat(athleteBenchmark.weeklyGoal) + goalAmount;
            } else if (athleteBenchmark.goalDirection === GOALS.DIRECTIONS.DOWN) {
              calculatedGoal = parseFloat(athleteBenchmark.weeklyGoal) - goalAmount;
            }

            break;
          }
          case GOALS.TYPES.TIME: {
            // Convert the time to seconds so it can be used in calculations
            const goalInSecondsBase = moment.duration(athleteBenchmark.weeklyGoalBase).asSeconds();
            const goalInSeconds = moment.duration(athleteBenchmark.weeklyGoal).asSeconds();
            goalAmount = goalInSecondsBase * (athleteBenchmark.weeklyGoalPercentage / 100);

            // Check goal direction and base don that either increase or decrease
            if (athleteBenchmark.goalDirection === GOALS.DIRECTIONS.UP) {
              calculatedGoal = new Date(1000 * (goalInSeconds + goalAmount)).toISOString().substr(11, 8);
            } else if (athleteBenchmark.goalDirection === GOALS.DIRECTIONS.DOWN) {
              calculatedGoal = new Date(1000 * (goalInSeconds - goalAmount)).toISOString().substr(11, 8);
            }

            break;
          }
          default:
        }

        const athleteBenchmarkDocRef = athleteBenchmarksRef.doc(athleteBenchmark.id);

        const updateRecord = {
          docRef: athleteBenchmarkDocRef,
          data: { weeklyGoal: `${calculatedGoal}` },
        };

        batchUpdateRecords.push(updateRecord);
      }
    }

    const batchUpdates = _chunk(batchUpdateRecords, 500).map((refs) => {
      const batch = db.batch();

      refs.forEach((ref) => {
        batch.update(ref.docRef, ref.data);
      });

      return batch.commit();
    });

    await Promise.all(batchUpdates);

    notify.success('Week calculated successfully');
  },

};
