import * as Bowser from 'bowser';
import store from '@/store';
import { getCurrentTimestamp } from '@/utils';

export default class ErrorEvent {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || null;
    this.username = object.username || 'Unknown';
    this.type = object.type || 'WEB';
    this.error = object.error || null;
    this.stacktrace = object.stacktrace || null;
    this.timestamp = object.timestamp || getCurrentTimestamp();
    this.version = object.version || process.env.VUE_APP_VERSION;
    this.context = object.context || null;
    this.metadata = object.metadata || null;
    this.clientDetails = object.clientDetails || null;
    this.ipAddress = object.ipAddress || null;
  }

  async sendError(error, context, metadata) {
    try {
      // eslint-disable-next-line no-console
      console.error(error, context, metadata);

      // Get the client browser details
      const browser = Bowser.getParser(window.navigator.userAgent);

      // Get current logged in user
      const currentUser = store.getters['session/user'];

      // Default to using the error as metadata if metadata was not sent
      const errorMetadata = metadata ? JSON.stringify(metadata) : JSON.stringify(error);

      this.username = currentUser.username || 'Unknown';
      this.error = error.message;
      this.stacktrace = error.stack;
      this.metadata = errorMetadata;
      this.clientDetails = JSON.stringify(browser.getResult());
      this.context = context;

      store.dispatch('logs/addError', this);

      return Promise.resolve();
    } catch {
      return Promise.resolve();
    }
  }
}
