import User from '../../../models/User';

export default {
  sidebar(currentState, value) {
    const state = currentState;
    state.sidebar = value;
  },

  user(currentState, value) {
    const state = currentState;
    state.user = new User(value);
  },

  userMenu(currentState, value) {
    const state = currentState;
    state.userMenu = value;
  },

  profilePanel(currentState, value) {
    const state = currentState;
    state.profilePanel = value;
  },

  deletedUsers(currentState, value) {
    const state = currentState;
    state.deletedUsers = value;
  },
};
