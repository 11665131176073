import moment from 'moment';

export default class BaseModel {
  constructor(existingObject) {
    const object = { ...existingObject };

    if (object.id) {
      this.id = object.id;
    }

    this.createdAt = object.createdAt || moment(new Date()).format('YYYYMMDDHHmmssSSS');
    this.updatedAt = object.updatedAt || moment(new Date()).format('YYYYMMDDHHmmssSSS');
  }

  serialize() {
    return JSON.parse(JSON.stringify(this));
  }

  removeEmptyArrays() {
    const object = this;

    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        if (object[key].length > 0) {
          for (let i = 0; i < object[key].length; i += 1) {
            if (object[key][i] instanceof BaseModel) {
              object[key][i].removeEmptyArrays();
            }
          }
        } else {
          delete object[key];
        }
      }
    });
  }
}
