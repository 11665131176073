import toTop from '@/utils/animateScroll';
import notify from '@/utils/notify';

export default {
  toTop,
  notify,
  install(VueInstance) {
    const Vue = VueInstance;
    Vue.prototype.$toTop = toTop.scrollToTop;
    Vue.prototype.$notify = notify;
  },
};
