import _cloneDeep from 'lodash/cloneDeep';

import notify from '@/utils/notify';
import { readFile } from '@/utils';

export default {

  addError({ commit, getters }, error) {
    const errors = _cloneDeep(getters.errors);
    errors.unshift(error);

    // Remove old errors
    if (errors.length > 10) {
      errors.pop();
    }

    commit('errors', errors);
  },

  async importLogs({ commit }, logFileEvent) {
    try {
      if (logFileEvent) {
        if (logFileEvent.target.files.length > 0) {
          const fileContent = await readFile(logFileEvent.target.files[0]);
          const errorLogs = JSON.parse(fileContent);

          commit('errors', errorLogs);
        }
      }

      notify.success('Successfully imported logs.');
      return Promise.resolve();
    } catch (error) {
      notify.error(error);
      throw error;
    }
  },

};
