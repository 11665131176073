import moment from 'moment';

import { GOALS } from '@/config/constants';
import BaseModel from './BaseModel';

export default class AthleteBenchmarkHistory extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.goal = object.goal || '';
    this.goalType = object.goalType || GOALS.TYPES.WEIGHT;
    this.goalDirection = object.goalDirection || GOALS.DIRECTIONS.UP;
    this.recent = object.recent || '';
    this.scoreDate = object.scoreDate || moment(new Date()).format('YYYY-MM-DD');
  }
}
