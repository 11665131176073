import _cloneDeep from 'lodash/cloneDeep';

export default {
  benchmark(currentState, value) {
    const state = currentState;
    state.benchmark = _cloneDeep(value);
  },

  benchmarks(currentState, value) {
    const state = currentState;
    state.benchmarks = _cloneDeep(value);
  },

  athleteBenchmarks(currentState, value) {
    const state = currentState;
    state.athleteBenchmarks = _cloneDeep(value);
  },
};
