import Composition from './Composition';

export default class BodyComposition {
  constructor(existingObject) {
    const object = { ...existingObject };

    this.height = object.height || 150;
    this.weight = new Composition(object.weight, { current: 50, goal: 50 });
    this.skinFold = new Composition(object.skinFold, { current: 50, goal: 50 });
  }

  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
}
