import _cloneDeep from 'lodash/cloneDeep';

export default {
  profiles(currentState, value) {
    const state = currentState;
    state.profiles = _cloneDeep(value);
  },

  activeProfile(currentState, value) {
    const state = currentState;
    state.activeProfile = value;
  },
};
