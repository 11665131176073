import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/app',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "route-layout" */ '@/layouts/Main'),
      children: [
        // {
        //   path: '',
        //   name: 'MainDashboardRoot',
        //   component: () => import(/* webpackChunkName: "route-main" */ '@/views/main/Dashboard'),
        // },
        {
          path: 'admin/ad-management',
          name: 'AdminAdManagement',
          component: () => import(/* webpackChunkName: "route-admin" */ '@/views/admin/AdManagement'),
        },
        {
          path: 'admin/user-management',
          name: 'AdminUserManagement',
          component: () => import(/* webpackChunkName: "route-admin" */ '@/views/admin/UserManagement'),
        },
        {
          path: 'profile',
          name: 'MainProfile',
          component: () => import(/* webpackChunkName: "route-main" */ '@/views/main/Profile'),
        },
        {
          path: 'coach/dashboard',
          name: 'CoachDashboard',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Dashboard'),
        },
        {
          path: 'athlete/dashboard',
          name: 'AthleteDashboard',
          component: () => import(/* webpackChunkName: "route-athlete" */ '@/views/athlete/Dashboard'),
        },
        {
          path: 'athlete/benchmark/:benchmarkType',
          name: 'AthleteScore',
          props: true,
          component: () => import(/* webpackChunkName: "route-athlete" */ '@/views/main/BenchmarkScore'),
        },
        {
          path: 'coach/athletes',
          name: 'CoachAthletes',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/AthleteList'),
        },
        {
          path: 'coach/athletes/:mode',
          name: 'CoachAthlete',
          props: true,
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Athlete'),
        },
        {
          path: 'coach/groups',
          name: 'CoachGroups',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/GroupList'),
        },
        {
          path: 'coach/groups/:mode',
          name: 'CoachGroup',
          props: true,
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Group'),
        },
        {
          path: 'coach/sessions',
          name: 'CoachSessions',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Sessions'),
        },
        {
          path: 'coach/reports',
          name: 'CoachReports',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Reports'),
        },
        {
          path: 'coach/benchmarks',
          name: 'CoachBenchmarks',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/BenchmarkList'),
        },
        {
          path: 'coach/benchmarks/:mode',
          name: 'CoachBenchmark',
          props: true,
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/Benchmark'),
        },
        {
          path: 'coach/scores',
          name: 'CoachScores',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/coach/BenchmarkScoreList'),
        },
        {
          path: 'coach/score',
          name: 'CoachScore',
          component: () => import(/* webpackChunkName: "route-coach" */ '@/views/main/BenchmarkScore'),
        },
        {
          path: 'athlete/workouts',
          name: 'AthleteWorkouts',
          component: () => import(/* webpackChunkName: "route-athlete" */ '@/views/athlete/Workouts'),
        },
        {
          path: 'messages',
          name: 'MainMessages',
          component: () => import(/* webpackChunkName: "route-main" */ '@/views/main/Messages'),
        },
        {
          path: 'help',
          name: 'MainHelp',
          component: () => import(/* webpackChunkName: "route-main" */ '@/views/main/Help'),
        },

        // TEMP SHOULD BE REMOVED! TEMP SHOULD BE REMOVED! TEMP SHOULD BE REMOVED! TEMP SHOULD BE REMOVED!
        {
          path: 'temp-usermanagement',
          name: 'TempUserManagement',
          component: () => import(/* webpackChunkName: "temp" */ '@/views/temp/TempUserManagement'),
        },
      ],
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "route-layout" */ '@/layouts/Blank'),
      children: [
        {
          path: '',
          name: 'LoginRoot',
          component: () => import(/* webpackChunkName: "route-dashboard" */ '@/views/auth/Login'),
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "route-auth" */ '@/views/auth/Login'),
        },
        {
          path: 'logout',
          name: 'Logout',
          component: () => import(/* webpackChunkName: "route-auth" */ '@/views/auth/Logout'),
        },
        {
          path: 'logs',
          name: 'Logs',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "route-logs" */ '@/views/error/LogViewer'),
        },
        {
          path: '*',
          name: '404',
          component: () => import(/* webpackChunkName: "route-error" */ '@/views/error/404'),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const currentUser = store.getters['session/user'];

  // Check if auth is required for the route
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    if (!currentUser.id) {
      next({
        name: 'Login',
        query: { redirect: `${window.location.origin}${to.fullPath}` },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
