// /* eslint-disable */
import notify from '@/utils/notify';
import { db } from '@/utils/firebase';

import ErrorEvent from '../../../models/ErrorEvent';
import BodyComposition from '../../../models/BodyComposition';

const usersRef = db.collection('users');

export default {
  async getBodyComposition({ commit }, athleteId) {
    try {
      const userDoc = await usersRef.doc(athleteId).get();

      if (userDoc.empty) {
        commit('bodyComposition', {});
        return Promise.resolve({});
      }

      const bodyComposition = new BodyComposition(userDoc.data().bodyComposition);
      commit('bodyComposition', bodyComposition);

      return bodyComposition;
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Body Composition');

      return Promise.resolve();
    }
  },

  async updateBodyComposition({ commit }, payload) {
    try {
      if (!payload) {
        const errorMessage = 'An unexpected error occured, please try again later';
        notify.error(errorMessage);
        return Promise.resolve();
      }

      const { athleteId, bodyComposition } = payload;
      const bodyCompositionData = new BodyComposition(bodyComposition);

      await usersRef.doc(athleteId).update({ bodyComposition: bodyCompositionData.serialize() });

      commit('bodyComposition', payload.bodyComposition);

      notify.success('Body Composition updated successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Body Composition');

      return Promise.resolve();
    }
  },
};
