import { ValidationProvider } from 'vee-validate';

export default {
  inheritAttrs: false,

  components: {
    ValidationProvider,
  },

  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    light: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: '',
    };
  },

  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },

    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

};
