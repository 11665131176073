import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/functions';

// Initialize Firebase
const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(config);
const db = firebase.firestore();

// Check whether emulator must be used for
if (process.env.VUE_APP_EMU) {
  db.settings({
    host: 'localhost:8888',
    ssl: false,
  });

  firebase.functions().useFunctionsEmulator('http://localhost:5001');
} else {
  db.enablePersistence({ synchronizeTabs: true });
}

const performance = app.performance();
const storage = app.storage();
const analytics = app.analytics();
const { firestore } = firebase;

export {
  app,
  db,
  storage,
  firestore,
  performance,
  analytics,
};
