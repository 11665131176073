import { boolean } from 'boolean';

import { USER_ROLES } from '../config/constants';

import BaseModel from './BaseModel';
import BodyComposition from './BodyComposition';

export default class User extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.id = object.id || object.email ? object.email.toLowerCase() : object.email;
    this.userId = object.userId || null;
    this.fullname = object.fullname || '';
    this.email = object.email ? object.email.toLowerCase() : object.email || '';
    this.role = object.role || USER_ROLES.ATHLETE;
    this.isActive = object.isActive !== undefined ? object.isActive : true;
    this.isVerified = boolean(object.isVerified);
    this.isAdmin = boolean(object.isAdmin);
    this.displayWelcome = object.displayWelcome !== undefined ? object.displayWelcome : true;

    if (this.role === USER_ROLES.ATHLETE) {
      this.position = object.position || null;
      this.isInjured = object.isInjured || false;
      this.rating = object.rating || 0;
      this.profileId = object.profileId || 0;
      this.bodyComposition = new BodyComposition(object.bodyComposition);
    } else {
      this.activeProfile = object.activeProfile || '';
    }
  }
}
