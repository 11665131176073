import BaseModel from './BaseModel';

export default class Profile extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.name = object.name || '';
    this.sport = object.sport || '';
  }
}
