import animateScrollTo from 'animated-scroll-to';

function scrollToTop() {
  const options = {
    speed: 500,
    minDuration: 250,
    maxDuration: 3000,
    cancelOnUserAction: false,
  };
  animateScrollTo(0, options);
}

export default {
  scrollToTop,
};
