import router from '@/router';
import notify from '@/utils/notify';
import { db, app, analytics } from '@/utils/firebase';
import mainMenuList from '@/config/main-menu';
import { USER_ROLES, EMAIL_TEMPLATES } from '@/config/constants';

import ErrorEvent from '../../../models/ErrorEvent';
import User from '../../../models/User';
import Activation from '../../../models/Activation';
import Email from '../../../models/EmailTemplate';

const usersRef = db.collection('users');
const activationsRef = db.collection('activations');

export default {
  async login({ commit, dispatch }, user) {
    try {
      if (user.email.length < 4) {
        notify.error('Please enter an email address.');
        return Promise.resolve();
      }
      if (user.password.length < 4) {
        notify.error('Please enter a password.');
        return Promise.resolve();
      }

      await app
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);

      const userDoc = await usersRef.doc(user.email).get();
      const userDetails = new User(userDoc.data());

      // if (!userDetails.isVerified) {
      //   notify.warning(
      //     'Your account has not been Verified yet. Please request a verification from your coach',
      //     true,
      //   );
      //   return Promise.resolve();
      // }

      await commit('user', userDetails);
      dispatch('generateUserMenu');

      if (userDetails.role !== USER_ROLES.ATHLETE) {
        dispatch(
          'profiles/setActiveProfile',
          { coachId: userDetails.id, profileId: userDetails.activeProfile.id },
          {
            root: true,
          },
        );
      } else {
        // Get the coachID
        const coachAthleteSnap = await db
          .collection('coachAthletes')
          .where('athleteId', '==', userDetails.id)
          .where('profileId', '==', userDetails.profileId)
          .get();

        const coachAthleteData = coachAthleteSnap.docs[0].data();
        userDetails.coachId = coachAthleteData.coachId;

        dispatch(
          'profiles/setActiveProfile',
          { coachId: userDetails.coachId, profileId: userDetails.profileId },
          {
            root: true,
          },
        );
      }

      if (router.currentRoute.query.redirect) {
        router.push(router.currentRoute.query.redirect.replace(window.origin, ''));
      } else {
        switch (userDetails.role) {
          case USER_ROLES.COACH:
            router.push({ name: 'CoachDashboard' });
            break;
          case USER_ROLES.ATHLETE:
            router.push({ name: 'AthleteDashboard' });
            break;
          default:
            router.push('/logout');
            break;
        }
      }

      analytics.logEvent('login', { userId: user.email });
      notify.success('Logged in successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Login');

      return Promise.resolve();
    }
  },

  async logout({ commit }) {
    try {
      await app.auth().signOut();
      commit('user', { email: '' });

      notify.success('Logged out successfully');
      return router.push('/login');
    } catch (error) {
      notify.error('An error occured.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Logout');

      return Promise.resolve();
    }
  },

  generateUserMenu({ state, commit }) {
    const menuItems = [];

    mainMenuList.forEach((item) => {
      if (item.role === undefined ||
          item.role === '' ||
          item.role === state.user.role ||
          (item.role === 'Admin' && state.user.isAdmin)
      ) {
        const newItem = {
          title: item.title,
          icon: item.icon,
          name: item.name,
          role: item.role,
          params: item.params,
        };

        if (item.children && item.children.length > 0) {
          newItem.children = [];

          item.children.forEach((subItem) => {
            if (
              subItem.role === undefined ||
              subItem.role === '' ||
              subItem.role === state.user.role ||
              (item.role === 'Admin' && state.user.isAdmin)
            ) {
              const newSubItem = {
                name: subItem.name,
                title: subItem.title,
                role: subItem.role,
                params: subItem.params,
              };

              newItem.children.push(newSubItem);
            }
          });
        }

        menuItems.push(newItem);
      }
    });

    commit('userMenu', menuItems);
  },

  async  sendActivationCode({ dispatch }, payload) {
    try {
      // Check if activation already exists
      const existingActivationSnap = await activationsRef
        .where('userId', '==', payload.userId)
        .get();

      if (existingActivationSnap.docs.length > 0) {
        await activationsRef.doc(existingActivationSnap.docs[0].id).delete();
      }

      const activationDocRef = activationsRef.doc();
      const newActivation = new Activation();
      newActivation.token = activationDocRef.id;
      newActivation.userId = payload.userId;

      await activationDocRef.set(newActivation.serialize());

      // Build activation url
      const activationUrl = `${window.location.origin}/activate-account?token=${
        newActivation.token
      }`;

      const activationEmail = new Email({
        to: payload.userId,
        subject: 'Activate Account',
        template: {
          name: EMAIL_TEMPLATES.ACTIVATE_ACCOUNT,
          data: {
            fullname: payload.fullname,
            activationUrl,
          },
        },
      });

      await dispatch('emails/sendEmail', activationEmail, { root: true });

      return Promise.resolve();
    } catch (error) {
      notify.error('An error occured.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Send Activation Code');

      return Promise.resolve();
    }
  },

  async monitorKilledUsers(context, userId) {
    try {
      db
        .collection('killedUsers')
        .where('userId', '==', userId)
        .onSnapshot(async (userSnap) => {
          userSnap.forEach(async (userDoc) => {
            if (userDoc.exists) {
              await db
                .collection('killedUsers')
                .doc(userId)
                .delete();

              router.push('/logout');
            }
          });
        });

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Monitor Killed Users');

      return Promise.resolve();
    }
  },

  async killUserSession(context, userId) {
    try {
      await db
        .collection('killedUsers')
        .doc(userId)
        .set({ userId });
      notify.success('User session was logged out');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Kill User Session');

      return Promise.resolve();
    }
  },

};
