import { boolean } from 'boolean';

import { GOALS } from '@/config/constants';

import Benchmark from './Benchmark';
import User from './User';

export default class AthleteBenchmark extends Benchmark {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.id = `${object.benchmarkId}_${object.athleteId}`;
    this.athleteId = object.athleteId || null;
    this.athlete = new User(object.athlete);
    this.benchmarkId = object.benchmarkId || '';
    this.goal = object.goal || '';
    this.goalType = object.goalType || GOALS.TYPES.WEIGHT;
    this.goalDirection = object.goalDirection || GOALS.DIRECTIONS.UP;
    this.weeklyGoalPercentage = object.weeklyGoalPercentage || null;
    this.weeklyGoalBase = object.weeklyGoalBase || null;
    this.weeklyGoal = object.weeklyGoal || null;
    this.best = object.best || '';
    this.recent = object.recent || '';
    this.isMultiplied = boolean(object.isMultiplied);
    this.groups = object.groups || [];
  }
}
