// /* eslint-disable */
import notify from '@/utils/notify';
import { db } from '@/utils/firebase';

import ErrorEvent from '../../../models/ErrorEvent';

const emailsRef = db.collection('emails');

export default {
  async sendEmail(context, email) {
    try {
      await emailsRef.add(email);

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Send Email');

      return Promise.resolve();
    }
  },
};
