import User from '@/models/User';

export default {
  sidebar: false,
  user: new User(),
  userMenu: [],
  profilePanel: false,
  deletedUsers: [],

};
