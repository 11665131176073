// /* eslint-disable */
import notify from '@/utils/notify';
import { db } from '@/utils/firebase';
import router from '@/router';

import {
  insertStoreArrayItem,
  updateStoreArrayItem,
  deleteStoreArrayItem,
} from '@/utils/storeUtils';

import ErrorEvent from '../../../models/ErrorEvent';
import Group from '../../../models/Group';

const groupsRef = db.collection('groups');
const coachGroupsRef = db.collection('coachGroups');
const groupMembersRef = db.collection('groupMembers');

export default {
  async getCoachGroups({ commit, rootGetters }, coachUserId) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;
      const querySnaphot = await coachGroupsRef
        .where('coachId', '==', coachUserId)
        .where('profileId', '==', profileId)
        .get();

      if (querySnaphot.empty) {
        commit('groups', []);
        return Promise.resolve();
      }

      const getGroupPromises = [];
      querySnaphot.docs.forEach((groupDoc) => {
        getGroupPromises.push(groupsRef.doc(groupDoc.data().groupId).get());
      });

      const groupDocs = await Promise.all(getGroupPromises);
      const coachGroups = groupDocs.map((x) => new Group(x.data()));

      commit('groups', coachGroups);

      return Promise.resolve(coachGroups);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Coach Groups');

      return Promise.resolve();
    }
  },

  async getGroupMembers({ commit, rootGetters }, groupId) {
    const profileId = rootGetters['profiles/activeProfile'].id;

    try {
      const groupMembersSnap = await groupMembersRef
        .where('groupId', '==', groupId)
        .where('profileId', '==', profileId)
        .get();

      const groupMemberIds = groupMembersSnap.docs.map(x => x.id.split('_')[1]);
      commit('groupMembers', groupMemberIds);

      return Promise.resolve(groupMemberIds);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Group Members');

      return Promise.resolve();
    }
  },

  async getAthleteGroups({ commit }, athleteId) {
    try {
      const athleteGroupsSnap = await groupMembersRef
        .where('athleteId', '==', athleteId)
        .get();

      const groupIds = athleteGroupsSnap.docs.map(x => x.id.split('_')[0]);
      commit('athleteGroups', groupIds);

      return Promise.resolve(groupIds);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Athlete Groups');

      return Promise.resolve();
    }
  },

  async getAllGroups({ rootGetters }, coachId) {
    try {
      const groups = {};
      const groupsPromises = [];
      const profileId = rootGetters['profiles/activeProfile'].id;

      const coachGroupsSnap = await coachGroupsRef
        .where('coachId', '==', coachId)
        .where('profileId', '==', profileId)
        .get();

      coachGroupsSnap.forEach((coachGroupDoc) => {
        const coachGroupData = coachGroupDoc.data();

        groupsPromises.push(groupsRef.doc(coachGroupData.groupId).get());
        groups[coachGroupData.groupId] = coachGroupDoc.data();
      });

      const groupDocs = await Promise.all(groupsPromises);
      for (let i = 0; i < groupDocs.length; i += 1) {
        groups[groupDocs[i].id] = {
          ...groups[groupDocs[i].id],
          ...groupDocs[i].data(),
        };
      }

      return Promise.resolve(Object.values(groups).map((x) => new Group(x)));
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get All Groups');

      return Promise.resolve();
    }
  },

  async getCoachGroupMembers({ commit, dispatch, rootGetters }, coachId) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;

      const groupMembersSnap = await groupMembersRef
        .where('coachId', '==', coachId)
        .where('profileId', '==', profileId)
        .get();

      const groupMembers = [];
      const groups = await dispatch('getAllGroups', coachId);

      // eslint-disable-next-line
      for (const groupMemberDoc of groupMembersSnap.docs) {
        const groupMemberData = groupMemberDoc.data();

        const tempGroup = groups.find(x => x.id === groupMemberData.groupId);
        if (tempGroup) {
          groupMemberData.groupName = tempGroup.groupName;
        } else {
          groupMemberData.groupName = 'Unknown';
        }

        groupMembers.push(groupMemberData);
      }

      commit('coachGroupMembers', groupMembers);

      return Promise.resolve(groupMembers);
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Coach Group Members');

      return Promise.resolve();
    }
  },

  async createNewGroup({ commit }) {
    commit('group', new Group());

    router.push({
      name: 'CoachGroup',
      params: {
        mode: 'create',
      },
    });
  },

  async createGroup({ getters, rootGetters }, payload) {
    try {
      if (!payload.group) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      // Validate if the new groupName already existingUser
      const existingGroupName = getters.groups.find(
        x => x.groupName.toLowerCase() === payload.group.groupName.toLowerCase(),
      );
      if (existingGroupName) {
        notify.warning('A group with this name already exist, please use a unique name');
        return Promise.resolve();
      }

      const profileId = rootGetters['profiles/activeProfile'].id;
      const groupsDocRef = groupsRef.doc();

      const newGroup = new Group(payload.group);
      newGroup.id = groupsDocRef.id;
      newGroup.groupMemberCount = payload.athleteIds.length;
      newGroup.profileId = profileId;

      await groupsDocRef.set(newGroup.serialize());

      const coachGroupsId = `${payload.coachId}_${newGroup.id}`;
      await coachGroupsRef.doc(coachGroupsId).set({
        groupId: newGroup.id,
        coachId: payload.coachId,
        profileId,
      });

      if (payload.athleteIds && payload.athleteIds.length > 0) {
        const batch = db.batch();

        payload.athleteIds.forEach((athleteId) => {
          const groupMemberId = `${groupsDocRef.id}_${athleteId}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);
          batch.set(groupMemberDocRef, {
            groupId: groupsDocRef.id,
            athleteId,
            coachId: payload.coachId,
            profileId,
          });
        });

        await batch.commit();
      }

      insertStoreArrayItem('groups/groups', newGroup);
      notify.success('Group created successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Group');

      return Promise.resolve();
    }
  },

  async updateGroup({ getters, rootGetters }, payload) {
    try {
      const profileId = rootGetters['profiles/activeProfile'].id;
      const group = new Group(payload.group);
      group.groupMemberCount = payload.athleteIds.length;

      await groupsRef.doc(group.id).set(group.serialize());

      updateStoreArrayItem('groups/groups', group);

      if (payload.athleteIds) {
        const batch = db.batch();

        const deselectedMembers = getters.groupMembers.filter(
          x => !payload.athleteIds.includes(x),
        );

        // Batch Remove members deselected from the list
        deselectedMembers.forEach((memberId) => {
          const groupMemberId = `${group.id}_${memberId}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);
          batch.delete(groupMemberDocRef);
        });

        // Batch set new selected members
        payload.athleteIds.forEach((athleteId) => {
          const groupMemberId = `${group.id}_${athleteId}`;
          const groupMemberDocRef = groupMembersRef.doc(groupMemberId);
          batch.set(groupMemberDocRef, {
            groupId: group.id,
            athleteId,
            coachId: payload.coachId,
            profileId,
          });
        });

        await batch.commit();
      }

      notify.success('Group updated successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Group');

      return Promise.resolve();
    }
  },

  async deleteGroup(context, payload) {
    try {
      if (!payload.groupId) {
        notify.error('An unexpected error occured, please try again later');
        return Promise.resolve();
      }

      await groupsRef.doc(payload.groupId).delete();

      const coachGroupsId = `${payload.coachId}_${payload.groupId}`;
      const coachGroupsDeleteRef = coachGroupsRef.doc(coachGroupsId);
      const batch = db.batch();
      batch.delete(coachGroupsDeleteRef);
      batch.commit();

      const groupMembersSnap = await groupMembersRef
        .where('groupId', '==', payload.groupId)
        .get();

      // TO BE MOVED TO FUNCTIONS
      const groupMembersBatch = db.batch();
      groupMembersSnap.forEach((doc) => {
        groupMembersBatch.delete(doc.ref);
      });
      groupMembersBatch.commit();

      deleteStoreArrayItem('groups/groups', { id: payload.groupId });

      notify.success('Group deleted successfully');

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Group');

      return Promise.resolve();
    }
  },

  async deleteGroupMembers(context, athleteId) {
    try {
      const groupMembersSnap = await groupMembersRef
        .where('athleteId', '==', athleteId)
        .get();

      const batch = db.batch();

      groupMembersSnap.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      return Promise.resolve();
    } catch (error) {
      notify.error(error.message);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Delete Group Members');

      return Promise.resolve();
    }
  },

  async selectGroup({ commit }, group) {
    commit('group', group);

    router.push({
      name: 'CoachGroup',
      params: {
        mode: 'update',
      },
    });
  },

};
