import { boolean } from 'boolean';

import { BENCHMARK_TYPES, GOALS } from '../config/constants';

import BaseModel from './BaseModel';

export default class Benchmark extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.coachId = object.coachId || null;
    this.benchmarkName = object.benchmarkName || '';
    this.type = object.type || BENCHMARK_TYPES.GYM;
    this.goalType = object.goalType || GOALS.TYPES.WEIGHT;
    this.goalDirection = object.goalDirection || GOALS.DIRECTIONS.UP;
    this.goalIsMultiplied = boolean(object.goalIsMultiplied);
    this.goalValue = object.goalValue || null;
    this.weeklyGoalPercentage = object.weeklyGoalPercentage || null;
    this.profileId = object.profileId || null;
  }
}
