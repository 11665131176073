import BaseModel from './BaseModel';

export default class Group extends BaseModel {
  constructor(existingObject) {
    super(existingObject);

    const object = { ...existingObject };

    this.groupName = object.groupName || '';
    this.profileId = object.profileId || null;
    this.isActive = object.isActive !== undefined ? object.isActive : true;
    this.logo = object.logo || null;
    this.groupMemberCount = object.groupMemberCount || 0;
  }
}
